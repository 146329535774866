import React, { ReactElement } from "react";

export default function KidneyCancer(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            fill="none"
            viewBox="0 0 100 100"
        >
            <mask
                id="mask0_2_3027"
                style={{ maskType: "alpha" }}
                width="100"
                height="100"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H100V100H0z"></path>
            </mask>
            <g mask="url(#mask0_2_3027)">
                <path
                    fill="#14406c"
                    d="M52.7 5c-9.358 0-19.05 7.376-19.074 19.717-.007 4.32 1.697 7.622 3.347 10.818.736 1.427 1.449 2.818 1.998 4.266-3.264 1.517-6.837 3.82-10.185 7.36-9.444 9.986-13.614 25.765-12.395 46.896l9.652-.558c-1.037-17.975 2.285-31.643 9.61-39.53a24.874 24.874 0 012.96-2.701c-.502 1.285-1.096 2.562-1.705 3.866-1.645 3.526-3.347 7.17-3.355 11.452-.021 11.835 9.863 19.177 19.034 19.19h.058c7.467 0 31.828-2.91 31.894-40.33C84.605 7.966 60.209 5.012 52.703 5H52.7zm-.062 78.5l.006.668-.057-.668c-7.62-.015-16.952-7.074-16.934-16.914.006-3.57 1.753-7.23 3.318-10.586 1.655-3.546 3.02-7.17 3.029-11.5.008-4.368-.844-6.285-2.5-9.5-1.541-2.983-3.854-6.751-3.847-10.283C35.672 14.39 44.904 7.5 52.703 7.5 79.293 7.547 82.015 37.28 82 46c-.017 8.707-2.82 37.503-29.362 37.5z"
                ></path>
                <path
                    fill="#fff"
                    d="M18.5 91.5C17.189 62.041 27.624 48.404 38.904 42c1.44.954 1.483 5.782 0 6.677-13.44 8.107-16.376 28.681-15.57 42.823H18.5z"
                ></path>
                <path
                    fill="#C1312C"
                    d="M73.311 61.062h-1.76a5.982 5.982 0 00-.59-1.42l1.244-1.246a1.58 1.58 0 10-2.234-2.234l-1.245 1.245a6.024 6.024 0 00-1.42-.59v-1.76a1.58 1.58 0 10-3.159 0v1.76a6.106 6.106 0 00-1.42.59l-1.245-1.245a1.58 1.58 0 00-2.234 2.234l1.244 1.246a5.95 5.95 0 00-.59 1.42h-1.76a1.58 1.58 0 100 3.158h1.76c.135.506.337.978.59 1.423l-1.244 1.242a1.58 1.58 0 002.234 2.234l1.243-1.24c.442.255.917.453 1.421.587v1.76c0 .874.707 1.579 1.58 1.579.871 0 1.579-.706 1.579-1.58v-1.759a5.957 5.957 0 001.42-.587l1.245 1.242a1.58 1.58 0 002.234-2.234l-1.244-1.243c.253-.443.454-.915.59-1.423h1.759c.872 0 1.58-.706 1.58-1.58a1.578 1.578 0 00-1.578-1.58zM56.325 31.732v-1.76a5.953 5.953 0 001.421-.588l1.245 1.243a1.58 1.58 0 002.234-2.235l-1.243-1.242c.253-.443.454-.915.59-1.423h1.76a1.578 1.578 0 100-3.158h-1.76a5.958 5.958 0 00-.59-1.421l1.244-1.246a1.58 1.58 0 10-2.234-2.234l-1.245 1.245a6.019 6.019 0 00-1.42-.59v-1.76a1.58 1.58 0 10-3.159 0v1.76a6.105 6.105 0 00-1.42.59l-1.245-1.245a1.58 1.58 0 10-2.234 2.234l1.242 1.246c-.253.44-.454.915-.59 1.42h-1.76a1.58 1.58 0 100 3.158h1.76c.135.506.337.978.59 1.423l-1.245 1.242a1.58 1.58 0 000 2.234c.617.62 1.618.62 2.235 0l1.244-1.242c.442.256.917.453 1.421.588v1.76c0 .874.707 1.579 1.58 1.579a1.576 1.576 0 001.58-1.579zM75.233 35.797a2.485 2.485 0 11-4.97 0 2.485 2.485 0 014.97 0zM60.581 47.73a2.483 2.483 0 11-4.966 0 2.483 2.483 0 014.966 0zM49.446 65.127a2.485 2.485 0 11-4.971 0 2.485 2.485 0 014.97 0z"
                ></path>
            </g>
        </svg>
    );
}
